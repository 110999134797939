body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Overide ReactTable styles */

.ReactTable .rt-thead.-header {
  padding: 0.25rem 0 !important;
}

.ReactTable .rt-th {
  /* TABLE HEADER */
  border: none !important;
  color: #888888;
  font-size: 0.75rem;
  font-weight: 700;
  /* 	letter-spacing: -0.5px; */
  padding: 0.5rem !important;
  text-align: right;
  text-transform: uppercase;
}

.ReactTable .rt-th.center {
  text-align: center;
}

span.padding-left {
  padding-left: 2rem;
}

.ReactTable .rt-tr {
  /* TABLE ROW */
  align-items: center;
}

.ReactTable .rt-td {
  /* TABLE CELL */
  border: none !important;
  padding: 0.5rem !important;
  line-height: 40px;
  font-size: 0.875rem;
}

.ReactTable .rt-tfoot {
  padding: 0.25rem 0 0.5rem 0 !important;
  font-weight: 700;
  box-shadow: none !important;
}

.ReactTable {
  border: none !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  border-bottom: 1px solid #dedede !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #dedede !important;
}

.ReactTable .-pagination {
  box-shadow: none !important;
  border-top: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc div:first-child:after {
  content: ' ▲';
}

.ReactTable .rt-thead .rt-th.-sort-desc div:first-child:after {
  content: ' ▼';
}

.ReactTable div.-pagination {
  margin: auto;
  margin-top: 1.5rem;
  max-width: 350px;
  font-size: 0.875rem;
  color: #444 !important;
}

.ReactTable .-pagination .-btn {
  background: #fff !important;
  border-radius: 5px !important;
  color: #444 !important;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: #f2f2f2 !important;
}

.ReactTable .-pagination .-btn[disabled] {
  background: none !important;
}

.ReactTable .-pagination .-pageJump input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #dedede;
  border-radius: 4px;
  outline: none;
  width: 50px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bn-onboard-modal {
  z-index: 1500 !important;
}
